<template>
  <div class="zcxy">
    <div class="clearfix table-tools">
      <div class="buttons">
      </div>
      <div class="search">
        <a-form layout='inline'>
          <a-form-item>
            <a-tooltip placement="topLeft" >
              <template slot="title">
                <span>上课校区</span>
              </template>
              <a-select allowClear showSearch placeholder="请选择校区" mode="multiple" :maxTagCount="1" :maxTagTextLength='5' :filterOption="filterOption" @change="handleChange" style="width: 220px;">
                <a-select-option v-for="(d, index) of studios" :key="index" :value="d['studio_id']">{{ d['filter_name'] }}</a-select-option>
              </a-select>
            </a-tooltip>
          </a-form-item>
          <a-form-item>
            <a-tooltip placement="topLeft" >
              <template slot="title">
                <span>课程科目</span>
              </template>
              <a-select allowClear placeholder="请选择课程科目" :filterOption="filterOption" @change="handleSubjectChange" style="width: 180px">
                <a-select-option v-for="(d, index) of classCategorys" :key="index" :value="d['course_cate_id']">{{ d['filter_name'] }}</a-select-option>
              </a-select>
            </a-tooltip>
          </a-form-item>
          <a-form-item>
            <a-tooltip placement="topLeft" >
              <template slot="title">
                <span>班级状态</span>
              </template>
              <a-select allowClear placeholder="请选择班级状态" v-model="paramsData.status" style="width: 180px;">
                <a-select-option value="">全部</a-select-option>
                <a-select-option value="1">开班</a-select-option>
                <a-select-option value="0">结业</a-select-option>
              </a-select>
            </a-tooltip>
          </a-form-item>
          <a-form-item>
            <a-button @click="toScreen" type="primary">筛选</a-button>
          </a-form-item>
        </a-form>
      </div>
    </div>   
    <div class="dashboard bk-group">
      
      <statistics :count='counts' :col="6"/>
  
      <a-row class="mb16" :gutter="16">
          <a-col :lg="24" :xl="12">
            <classes :loading='loading'  :classesData ='classesData'/>
          </a-col>

          <a-col :lg="24" :xl="12">
            <classesStudents :loading='loading' :studentsData="studentsData"/>
          </a-col>
          
      </a-row>
    </div>

  </div>
</template>

<script>
import classes from './classes'
import classesStudents from './classes-students'
import statistics from '../xqypb/statistics'
export default {
  name: 'kbtj',
  components:{
    classes,
    classesStudents,
    statistics
  },
  data() {
    return {
      loading:false,
      classesData:{},
      studentsData:{},
      counts:[],
      studios:[],
      classCategorys:[],
      paramsData:{
        status:'',
        studio_id:'',
        course_cate_id:'',
      },
    }
  },

  created() {
    this.getList();
    this.getStudio();
    this.getClassCategory();
  },

  methods: {
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    async getList(obj){
      this.loading = true
      this.getCount(obj)
      let res = await this.$store.dispatch('analysisClassesIndexAction', obj)
      this.classesData = res.data.classes
      this.studentsData = res.data.students
      this.loading = false
    },
    async getStudio() {
      let res = await this.$store.dispatch('searchBelongStudioAction', {})
      this.studios = res.data
    },
    async getClassCategory() {
      let res = await this.$store.dispatch('searchCourseTypeAction', {})
      this.classCategorys = res.data
    },
    async getCount(obj){
      let res = await this.$store.dispatch('analysisClassesCountAction', obj)
      this.counts = res.data
    },
    handleChange(value){
      this.paramsData.studio_id = value;
    },
    handleSubjectChange(value){
      this.paramsData.course_cate_id = value;
    },
    toScreen(){
      let obj = {
        search:this.paramsData
      }
      this.getList(obj);
    }
  }
}
</script>
