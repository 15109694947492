<template>
   <a-card title="">
    <div>
      <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
      <ve-line 
        :mark-line="markLine" 
        :grid="{right: '5%'}" 
        :data="item" 
        :title="{
            text:'学员统计',
            subtext: start_date==end_date?`${start_date}`:`${start_date} ~ ${end_date}`,
            textStyle: {
                fontSize: 15,
            },
            subtextStyle: {
                fontSize: 12.5
            }
        }" 
        :extend="{yAxis:{
                        splitLine: {
                        lineStyle: {
                            type: 'dotted'
                        }
                        }
                    },dataZoom:[{type:'slider'}]}"  
        :legend="{show:false}"
        judge-width
        :settings="{ 
            lineStyle: {
                normal: {
                    width: 3
                }
            },
            areaStyle:{
                area: true,
                normal:{
                    color:{
                        type: 'linear',
                        x: 0,
                        y: 0,
                        x2: 0,
                        y2: 1,
                        colorStops: [{
                            offset: 1, color: '#effffb' // 0% 处的颜色
                        }, {
                            offset: 0, color: '#23d9ae' // 100% 处的颜色
                        }],
                        global: false // 缺省为 false
                    },
                    shadowColor: 'rgba(0, 0, 0, 0.1)',
                    shadowBlur: 10
                }
            },
            label: { show: true, position: 'top'}
        }"></ve-line>
        <LEmpty v-if="first>0 && item.rows.length == 0"/>
   </div>
  </a-card>
</template>

<script>
    export default {
        name:'studentL',
        props:['item' , 'start_date', 'end_date','loading'],
        data(){

            this.markLine = {
                symbol: ['none', 'none'], //去掉箭头
                data: [
                {
                    name: '平均线',
                    type: 'average'
                }
                ]
            },
            this.toolbox = {
                feature: {
                magicType: {type: ['line', 'bar']},
                saveAsImage: {pixelRatio:2}
                }
            },
            this.colors = ['#ec4758']
            return{
                first:0
            }
        },
        watch: {
            loading(newValue, oldValue) {
                this.first++;
            }
        },
    }
</script>

<style lang="scss" scoped>

</style>