<template>
  <div class="zcxy">

    <div class="dashboard bk-group">
      <a-row class="statistics-group" :gutter="16">
        <a-col class="statistics-panel" :lg="12" :xl="6">
          <div class="statistics-item">
            <div class="tit">本周新签 / 续费数</div>
            <div class="con"><strong>2</strong> / <small>0</small></div>
            <div class="foo">人</div>
          </div>
        </a-col>
        <a-col class="statistics-panel" :lg="12" :xl="6">
          <div class="statistics-item">
            <div class="tit">本月新签 / 续费数</div>
            <div class="con"><strong>2</strong> / <small>0</small></div>
            <div class="foo">人</div>
          </div>
        </a-col>
        <a-col class="statistics-panel" :lg="12" :xl="6">
          <div class="statistics-item">
            <div class="tit">上月新签 / 续费数</div>
            <div class="con"><strong>0</strong> / <small>0</small></div>
            <div class="foo">人</div>
          </div>
        </a-col>
        <a-col class="statistics-panel" :lg="12" :xl="6">
          <div class="statistics-item">
            <div class="tit">全部新签 / 续费数</div>
            <div class="con"><strong>656</strong> / <small>263</small></div>
            <div class="foo">人</div>
          </div>
        </a-col>
      </a-row>
    </div>
    
    <div class="clearfix table-tools">
      <div class="buttons">
      </div>
      <div class="search">
        <a-form layout='inline'>
          <a-form-item>
            <a-tooltip placement="topLeft" >
              <template slot="title">
                <span>所属校区</span>
              </template>
              <a-select allowClear showSearch placeholder="请选择校区" style="width: 180px;">
                <a-select-option value="jack">Jack</a-select-option>
                <a-select-option value="lucy">Lucy</a-select-option>
                <a-select-option value="Yiminghe">yiminghe</a-select-option>
              </a-select>
            </a-tooltip>
          </a-form-item>
          <a-form-item>
            <a-tooltip placement="topLeft" >
              <template slot="title">
                <span>时间筛选</span>
              </template>
              <a-date-picker placeholder="请选择时间"/>
            </a-tooltip>
          </a-form-item>
          <a-form-item>
            <a-tooltip placement="topLeft" >
              <template slot="title">
                <span>课程收费</span>
              </template>
              <a-select allowClear showSearch placeholder="请选择课程收费" style="width: 180px;">
                <a-select-option value="jack">Jack</a-select-option>
                <a-select-option value="lucy">Lucy</a-select-option>
                <a-select-option value="Yiminghe">yiminghe</a-select-option>
              </a-select>
            </a-tooltip>
          </a-form-item>
          <a-form-item>
            <a-button type="primary">搜索</a-button>
          </a-form-item>
        </a-form>
      </div>
    </div>
    <div class="chart" style="height: 400px; background: #ddd; margin-bottom: 20px;">图表1</div>
    <div class="chart" style="height: 400px; background: #ddd; margin-bottom: 20px;">图表1</div>
  </div>
</template>

<script>
export default {
  name: 'kcsftj',

  data() {
    return {
      visible: false
    }
  },

  created() {
  },

  methods: {
    changeTab() {

    }
  }
}
</script>
