import { render, staticRenderFns } from "./teacherSummate.vue?vue&type=template&id=4a70d330&scoped=true&"
import script from "./teacherSummate.vue?vue&type=script&lang=js&"
export * from "./teacherSummate.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4a70d330",
  null
  
)

export default component.exports