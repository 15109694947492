<template>
  <a-card title="">
        <div>
          <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
          <ve-line
          judge-width
          :toolbox="toolbox"
          height="360px"
          :title="{
            text:'签约金额（元）',
            subtext: start_date==end_date?`${start_date}`:`${start_date} ~ ${end_date}`,
            textStyle: {
                fontSize: 15,
            },
            subtextStyle: {
                fontSize: 12.5
            }
          }"
          :data="item" :mark-line="markLine" :grid="{right: '5%'}" :extend="{
            yAxis:{
            splitLine: {
            lineStyle: {
                type: 'dotted'
            }
            }
          },
            series:{
            type:'bar',
            barMaxWidth: 10,
            itemStyle:{
                normal:{
                    color: function(params) {
                        return {
                            type: 'linear',
                            x:1,
                            y:1,
                            x2:0,
                            y2:0,
                            colorStops: [{
                                offset: 0,
                                color: '#ffffff'
                                
                            }, {
                                offset: 1,                    
                                color: '#23d9ae'
                            }],
                        }
                    },
                    barBorderRadius:[30,30,0,0]
                }
            }
          },dataZoom:[{type:'slider'}]}" :legend="{show:false}" :settings="{ 
              yAxisType: ['KMB', 'KMB'],
              lineStyle: {
                  normal: {
                      width: 1
                  }
              },
              areaStyle:{
                  area: true,
                  normal:{
                      color:{
                          type: 'linear',
                          x: 0,
                          y: 0,
                          x2: 0,
                          y2: 1,
                          colorStops: [{
                              offset: 1, color: '#effffb' // 0% 处的颜色
                          }, {
                              offset: 0, color: '#23d9ae' // 100% 处的颜色
                          }],
                          global: false // 缺省为 false
                      },
                      shadowColor: 'rgba(0, 0, 0, 0.1)',
                      shadowBlur: 10
                  }
              },
              label: { show: true, position: 'top'}}"></ve-line>
              <LEmpty v-if="first>0 && item.rows.length == 0"/>
        </div>

  </a-card>
</template>

<script>
export default {
  name: 'contractStatis',
  props:['item' , 'start_date', 'end_date','loading'],
  data(){
      this.toolbox = {
        feature: {
          magicType: {type: ['line', 'bar']},
          saveAsImage: {pixelRatio:2}
        }
      },
      this.markLine = {
        symbol: ['none', 'none'], //去掉箭头
        data: [
          {
            name: '平均线',
            type: 'average'
          }
        ]
      }
    return{
      first:0
    }
  },
  watch: {
      loading(newValue, oldValue) {
          this.first++;
      }
  },
}
</script>
