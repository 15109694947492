<template>
    <a-card title="">
        <div>
            <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
            <div style="height: 400px;">
            <ve-bar 
                :legend="{show:false}"
                judge-width
                :colors="colors" :data="item" :mark-line="markLine" 
                :grid="{right: '5%'}" 
                :toolbox="toolbox"
                height="425px"
                    :title="{
                        text:'学员上课统计',
                        subtext: start_date==end_date?`${start_date}`:`${start_date} ~ ${end_date}`,
                        textStyle: {
                            fontSize: 15,
                        },
                        subtextStyle: {
                            fontSize: 12.5
                        }
                    }" 
                    :extend="{
                         xAxis:{
                        splitLine: {
                        lineStyle: {
                            type: 'dotted'
                        }
                        }
                    },
            yAxis:{
            inverse: true,  
            splitLine:{show: false},
            axisLabel: {
                color: '#333',
                rich: {
                    b: {
                        color: '#f4bc2f',
                        align: 'center',
                    }
                },
                formatter: function(params,index) {
                    return [
                            `${params} {b| ${index + 1}}`
                        ].join('\n')
                }
            }
        }, series:{type:'bar',barMaxWidth:10,margin:80,itemStyle:{
            normal:{
                color: function(params) {
                    return {
                        type: 'linear',
                        colorStops: [{
                            offset: 0,
                            color: '#effffb'
                            
                        }, {
                            offset: 1,                    
                            color: '#23d9ae'
                        }],
                    }
            },barBorderRadius:[0,30,30,0]}}},dataZoom:[{type:'slider'}]}" 
                    :settings="{ 
                    lineStyle: {
                        normal: {
                            width: 1
                        }
                    },
                    areaStyle:{
                        area: true,
                        normal:{
                            color:{
                                type: 'linear',
                                x: 0,
                                y: 0,
                                x2: 0,
                                y2: 1,
                                colorStops: [{
                                    offset: 1, color: '#ffffff' // 0% 处的颜色
                                }, {
                                    offset: 0, color: '#23d9ae' // 100% 处的颜色
                                }],
                                global: false // 缺省为 false
                            },
                            shadowColor: 'rgba(0, 0, 0, 0.1)',
                            shadowBlur: 10
                        }
                    },
                    label: { show: true, position: 'top'}}"></ve-bar>
            </div>
            <LEmpty v-if="first>0 && item.rows.length == 0"/>
        </div>
    </a-card>
</template>

<script>
    export default {
        name:'roomBook',
        props:['item' , 'start_date', 'end_date','loading'],
        data() {
            this.markLine = {
                symbol: ['none', 'none'], //去掉箭头
                data: [
                {
                    name: '平均线',
                    type: 'average'
                }
                ]
            },
            this.toolbox = {
                feature: {
                magicType: {},
                saveAsImage: {pixelRatio:2}
                }
            },
            this.colors = ['#23d9ae']
            return {
                first:0
            }
        },
        watch: {
            loading(newValue, oldValue) {
                this.first++;
            }
        },
    }
</script>

<style lang="scss" scoped>

</style>