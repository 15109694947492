<template>
  <div class="zcxy">
    <div class="clearfix table-tools">
      <div class="buttons">
      </div>
      <div class="search">
        <a-form layout='inline'>
          <a-form-item>
            <a-tooltip placement="topLeft" >
              <template slot="title">
                <span>时间筛选</span>
              </template>
              <a-range-picker
                :ranges="rangesData"
                :value='rangesValue'
                :disabled-date="disabledDate" :allowClear="false"
                :default-value="[moment(paramsData.start_date,'YYYY-MM-DD'),moment(paramsData.end_date,'YYYY-MM-DD')]"  style="width: 240px"
                @change="(date, dateString)=>onChange(date, dateString,1)" />
            </a-tooltip>
          </a-form-item>
          <a-form-item>
            <a-button @click="toScreen" type="primary">筛选</a-button>
          </a-form-item>
        </a-form>
      </div>
    </div>
    <div class="dashboard bk-group" >
      <statistics :count='counts' col="6" />
      <a-row class="mb16" :gutter="16">
          <a-col :lg="24" :xl="12">
            <income :loading='loading' :item ='incomeData' :start_date='paramsData.start_date' :end_date='paramsData.end_date'/>
          </a-col>
          <a-col :lg="24" :xl="12">
            <outcome :loading='loadingO' :item ='outcomeData' :start_date='paramsData.start_date' :end_date='paramsData.end_date'/>
          </a-col>
      </a-row>
    </div>
  </div>
</template>

<script>
    import income from './income'
    import outcome from './outcome'
    import moment from 'moment'
    import ranges from "@/common/mixins/ranges";
    import statistics from '../xqypb/statistics'
    export default {
        name:'kctj',
        components:{
            income,
            outcome,
            statistics
        },
        data() {
            return {
                loading: false,
                loadingO:false,
                incomeData:{},
                outcomeData:{},
                counts:[],
                paramsData:{
                    start_date:'',
                    end_date:'',
                },
            }
        },
        mixins: [ranges],
        created(){
            this.paramsData.start_date = moment().subtract('days',30).format('YYYY-MM-DD')
            this.paramsData.end_date = moment().subtract('days',0).format('YYYY-MM-DD')
            this.rangesValue = [moment(this.paramsData.start_date,'YYYY-MM-DD'),moment(this.paramsData.end_date,'YYYY-MM-DD')]
            this.getList();
        },
        methods:{
            moment,
            getList(obj){
                this.getCount(obj)
                this.getIncome(obj)
                this.getOutcome(obj)
            },
            async getIncome(obj){
                this.loading = true
                let res = await this.$store.dispatch('analysisStockIncomeAction', obj)
                this.incomeData = res.data
                this.loading = false
            },
            async getOutcome(obj){
                this.loadingO = true
                let res = await this.$store.dispatch('analysisStockOutcomeAction', obj)
                this.outcomeData = res.data
                this.loadingO = false
            },
            async getCount(obj){
              let res = await this.$store.dispatch('analysisStockCountAction', obj)
              this.counts = res.data
            },
            toScreen(){
                let obj = {
                    search:this.paramsData
                }
                this.getList(obj);
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>