<template>
  <div class="zcxy">
    <div class="clearfix table-tools">
      <div class="buttons">
      </div>
      <div class="search">
        <a-form layout='inline'>
          <a-form-item>
            <a-radio-group :value="paramsData.filter_type" @change="handleButChange">
              <a-radio-button value="day">
                日
              </a-radio-button>
              <a-radio-button value="month">
                月
              </a-radio-button>
              <a-radio-button value="year">
                年
              </a-radio-button>
            </a-radio-group>
          </a-form-item>
          <a-form-item>
            <a-tooltip placement="topLeft" >
              <template slot="title">
                <span>时间筛选</span>
              </template>
              <a-range-picker
                :ranges="rangesData"
                :value='rangesValue'
                :disabled-date="disabledDate" :allowClear="false"
                :default-value="[moment(paramsData.start_date,'YYYY-MM-DD'),moment(paramsData.end_date,'YYYY-MM-DD')]"  style="width: 240px"
                @change="(date, dateString)=>onChange(date, dateString,1)" />
            </a-tooltip>
          </a-form-item>
          <a-form-item>
            <a-tooltip placement="topLeft" >
              <template slot="title">
                <span>上课校区</span>
              </template>
              <a-select allowClear showSearch placeholder="请选择校区" mode="multiple" :maxTagCount="1" :maxTagTextLength='5' :filterOption="filterOption" @change="handleChange" style="width: 220px;">
                <a-select-option v-for="(d, index) of studios" :key="index" :value="d['studio_id']">{{ d['filter_name'] }}</a-select-option>
              </a-select>
            </a-tooltip>
          </a-form-item>
          <a-form-item>
            <a-button @click="toScreen" type="primary">筛选</a-button>
          </a-form-item>
        </a-form>
      </div>
    </div>
    <div class="dashboard bk-group">
      <statistics :count='counts'/>
      <a-row class="mb16" :gutter="16">
          <a-col :lg="24" :xl="12">
            <courseBalance :loading='loading' :item ='course' :start_date='paramsData.start_date' :end_date='paramsData.end_date'/>
          </a-col>
          <a-col :lg="24" :xl="12">
            <courseBalanceCosts :loading='loadingC' :item ='courseC' :start_date='paramsData.start_date' :end_date='paramsData.end_date'/>
          </a-col>
          <a-col :lg="24" :xl="12">
            <courseBalanceStudio :loading='loadingS' :item ='courseS' :start_date='paramsData.start_date' :end_date='paramsData.end_date'/>
          </a-col>
          <a-col :lg="24" :xl="12">
            <courseBalanceStudioCosts :loading='loadingSC' :item ='courseSC' :start_date='paramsData.start_date' :end_date='paramsData.end_date'/>
          </a-col>
      </a-row>
    </div>
  </div>
</template>

<script>
import ranges from "@/common/mixins/ranges";
import moment from 'moment'
import statistics from '../xqypb/statistics'
import courseBalance from './courseBalance'
import courseBalanceCosts from './courseBalanceCosts'
import courseBalanceStudio from './courseBalanceStudio'
import courseBalanceStudioCosts from './courseBalanceStudioCosts'
    export default {
        name:'kxsjtj',
        data() {
            return {
                loading:false,
                loadingC:false,
                loadingS:false,
                loadingSC:false,
                course:{},
                courseC:{},
                courseS:{},
                courseSC:{},
                counts:[],
                studios:[],
                paramsData:{
                    start_date:'',
                    end_date:'',
                    studio_id:'',
                    filter_type:'day'
                },
            }
        },
        mixins: [ranges],
        components:{
            statistics,
            courseBalance,
            courseBalanceCosts,
            courseBalanceStudio,
            courseBalanceStudioCosts,
        },
        created() {
            this.paramsData.start_date = moment().subtract('days',30).format('YYYY-MM-DD')
            this.paramsData.end_date = moment().subtract('days',0).format('YYYY-MM-DD')
            this.rangesValue = [moment(this.paramsData.start_date,'YYYY-MM-DD'),moment(this.paramsData.end_date,'YYYY-MM-DD')]
            this.getList();
            this.getStudio();
        },
        methods: {
            filterOption(input, option) {
                return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
            },
            getList(obj) {
                this.getCount(obj)
                this.getCourseBalance(obj)
                this.getCourseBalanceCosts(obj)
                this.getCourseBalanceStudio(obj)
                this.getCourseBalanceStudioCosts(obj)
            },
            async getCourseBalance(obj){
                this.loading = true
                let res = await this.$store.dispatch('analysisCourseBalanceAction', obj)
                this.course = res.data
                this.loading = false
            },
            async getCourseBalanceCosts(obj){
                this.loadingC = true
                let res = await this.$store.dispatch('analysisCourseBalanceCostsAction', obj)
                this.courseC = res.data
                this.loadingC = false
            },
            async getCourseBalanceStudio(obj){
                this.loadingS = true
                let res = await this.$store.dispatch('analysisCourseBalanceStudioAction', obj)
                this.courseS = res.data
                this.loadingS = false
            },
            async getCourseBalanceStudioCosts(obj){
                this.loadingSC = true
                let res = await this.$store.dispatch('analysisCourseBalanceStudioCostsAction', obj)
                this.courseSC = res.data
                this.loadingSC = false
            },
            async getStudio() {
                let res = await this.$store.dispatch('searchBelongStudioAction', {})
                this.studios = res.data
            },
            async getCount(obj){
                let res = await this.$store.dispatch('analysisCourseBalanceCountAction', obj)
                this.counts = res.data
            },
            handleChange(value){
                this.paramsData.studio_id = value;
            },
            handleButChange(e){
                this.paramsData.filter_type = e.target.value
                this.toScreen();
            },
            toScreen(){
                let obj = {
                    search:this.paramsData
                }
                this.getList(obj);
            }
        },
    }
</script>

<style lang="scss" scoped>

</style>