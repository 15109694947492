<template>
  <div class="zcxy">
    <div class="clearfix table-tools">
      <div class="buttons">
      </div>
      <div class="search">
        <a-form layout='inline'>
          <a-form-item>
            <a-radio-group :value="paramsData.filter_type" @change="handleButChange">
              <a-radio-button value="day">
                日
              </a-radio-button>
              <a-radio-button value="month">
                月
              </a-radio-button>
              <a-radio-button value="year">
                年
              </a-radio-button>
            </a-radio-group>
          </a-form-item>
          <a-form-item>
            <a-tooltip placement="topLeft" >
              <template slot="title">
                <span>时间筛选</span>
              </template>
              <a-range-picker
                :ranges="rangesData"
                :value='rangesValue'
                :disabled-date="disabledDate" :allowClear="false"
                :default-value="[moment(paramsData.start_date,'YYYY-MM-DD'),moment(paramsData.end_date,'YYYY-MM-DD')]"  style="width: 240px"
                @change="(date, dateString)=>onChange(date, dateString,1)" />
            </a-tooltip>
          </a-form-item>
          <a-form-item>
            <a-button @click="toScreen" type="primary">筛选</a-button>
          </a-form-item>
        </a-form>
      </div>
    </div>
    <div class="dashboard bk-group">
      <statistics :count='counts'/>
      <a-row class="mb16" :gutter="16">
          <a-col :lg="24" :xl="12">
            <credits :loading='loading' :item ='creditsData' :start_date='paramsData.start_date' :end_date='paramsData.end_date'/>
          </a-col>
          <a-col :lg="24" :xl="12">
            <creditsCosts :loading='loadingC' :item ='creditsCostsData' :start_date='paramsData.start_date' :end_date='paramsData.end_date'/>
          </a-col>
      </a-row>
    </div>
  </div>
</template>

<script>
import ranges from "@/common/mixins/ranges"
import statistics from '../xqypb/statistics'
import moment from 'moment'
import credits from './credits'
import creditsCosts from './creditsCosts'

    export default {
        name:'jftj',
        data() {
            return {
                loading: false,
                loadingC: false,
                creditsData:{},
                creditsCostsData:{},
                counts:[],
                paramsData:{
                    start_date:'',
                    end_date:'',
                    filter_type:'day'
                },
            }
        },
        mixins: [ranges],
        components:{
            credits,
            creditsCosts,
            statistics
        },
        created () {
            this.paramsData.start_date = moment().subtract('days',30).format('YYYY-MM-DD')
            this.paramsData.end_date = moment().subtract('days',0).format('YYYY-MM-DD')
            this.rangesValue = [moment(this.paramsData.start_date,'YYYY-MM-DD'),moment(this.paramsData.end_date,'YYYY-MM-DD')]
            this.getList();
        },
        methods: {
            filterOption(input, option) {
                return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
            },
            getList(obj) {
                this.getCount(obj)
                this.getCredits(obj)
                this.getCreditsCosts(obj)
            },
            async getCredits(obj){
                this.loading = true
                let res = await this.$store.dispatch('analysisCreditsAction', obj)
                this.creditsData = res.data
                this.loading = false
            },
            async getCreditsCosts(obj){
                this.loadingC = true
                let res = await this.$store.dispatch('analysisCreditsCostsAction', obj)
                this.creditsCostsData = res.data
                this.loadingC = false
            },
            async getCount(obj){
                let res = await this.$store.dispatch('analysisCreditsCountAction', obj)
                this.counts = res.data
            },
            handleChange(value){
                this.paramsData.studio_id = value;
            },
            handleButChange(e){
                this.paramsData.filter_type = e.target.value
                this.toScreen();
            },
            toScreen(){
                let obj = {
                    search:this.paramsData
                }
                this.getList(obj);
            }
        },
    }
</script>

<style lang="scss" scoped>

</style>