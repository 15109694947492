<template>
  <div class="zcxy xqypb">
    <div class="clearfix table-tools">
      <div class="buttons">
      </div>
      <div class="search">
        <a-form layout='inline'>
          <a-form-item>
            <a-tooltip placement="topLeft" >
              <template slot="title">
                <span>时间筛选</span>
              </template>
              <a-range-picker
                :ranges="rangesData"
                :value='rangesValue'
                :disabled-date="disabledDate" :allowClear="false"
                :default-value="[moment(paramsData.start_date,'YYYY-MM-DD'),moment(paramsData.end_date,'YYYY-MM-DD')]"  style="width: 240px"
                @change="(date, dateString)=>onChange(date, dateString,1)" />
            </a-tooltip>
          </a-form-item>


          <a-form-item>
            <a-tooltip placement="topLeft" >
              <template slot="title">
                <span>上课校区</span>
              </template>
              <a-select allowClear showSearch placeholder="请选择校区" mode="multiple" :maxTagCount="1" :maxTagTextLength='5' :filterOption="filterOption" @change="handleChange" style="width: 220px;">
                <a-select-option v-for="(d, index) of studios" :key="index" :value="d['studio_id']">{{ d['filter_name'] }}</a-select-option>
              </a-select>
            </a-tooltip>
          </a-form-item>

          
          <a-form-item>
            <a-button @click="toScreen" type="primary">筛选</a-button>
          </a-form-item>
        </a-form>
      </div>
    </div>
    <div class="dashboard bk-group">
      <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
      <statistics :count='counts' :col="6"/>
      <a-row class="mb16" :gutter="16">
        <a-col :lg="24" :xl="12">
          <xqxstj :studio_sale_statis="studio_sale_statis" :loading="loading" />
        </a-col>
        <a-col :lg="24" :xl="12">
          <xsqytj :course_consultant_statis="course_consultant_statis" :loading="loading" />
        </a-col>

        <a-col :lg="24" :xl="12">
          <xqkcxs :course_sale_count="course_sale_count" :loading="loading" />
        </a-col>

        <a-col :lg="24" :xl="12">
          <xqkcje :course_sale_statis="course_sale_statis" :loading="loading" />
        </a-col>
      </a-row>
    </div>
  </div>
</template>

<script>
import ranges from "@/common/mixins/ranges";
import moment from 'moment'
import statistics from './statistics'
import xqxstj from './xqxstj'
import xsqytj from './xsqytj'
import xqkcxs from './xqkcxs'
import xqkcje from './xqkcje'

export default {
  name: 'xqypb',

  components: {
    statistics,
    xqxstj,
    xsqytj,
    xqkcxs,
    xqkcje,
  },

  data() {
    return {
      loading:false,
      start_date:'',
      end_date:'',
      studios:[],
      counts:[],
      course_consultant_statis:{},
      course_sale_count:{},
      course_sale_statis:{},
      studio_sale_statis:{},
      paramsData:{
        start_date:'',
        end_date:'',
        studio_id:'',
      },
    }
  },
  mixins: [ranges],
  created() {
    this.paramsData.start_date = moment().subtract('days',30).format('YYYY-MM-DD')
    this.paramsData.end_date = moment().subtract('days',0).format('YYYY-MM-DD')
    this.rangesValue = [moment(this.paramsData.start_date,'YYYY-MM-DD'),moment(this.paramsData.end_date,'YYYY-MM-DD')]
    this.getList();
    this.getStudio();
  },
  methods: {
    moment,
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    async getList(obj){
      this.getCount(obj)
      this.getCampusRank(obj)
    },
    async getCampusRank(obj){
      this.loading = true

      let res = await this.$store.dispatch('analysisCampusRankAction', obj);

      //校区销售金额
      this.studio_sale_statis= res.data.studio_sale_statis;

      //课程顾问签约金额
      this.course_consultant_statis= res.data.course_consultant_statis;

      //科目签约数量
      this.course_sale_count= res.data.course_sale_count;

      //科目签约金额
      this.course_sale_statis= res.data.course_sale_statis;

      this.loading = false
    },
    async getCount(obj){
      let res = await this.$store.dispatch('analysisCampusCountAction', obj)
      this.counts = res.data
    },
    async getStudio() {
      let res = await this.$store.dispatch('searchBelongStudioAction', {})
      this.studios = res.data
    },
    handleChange(value){
      this.paramsData.studio_id = value;
    },
    toScreen(){
      let obj = {
        search:this.paramsData
      }
      this.getList(obj);
    }
  }
}
</script>
