<template>
  <div>
    <a-breadcrumb class="breadcrumb">
      <a-breadcrumb-item>首页</a-breadcrumb-item>
      <a-breadcrumb-item>商业智能</a-breadcrumb-item>
    </a-breadcrumb>
    <div class="main-body students">
      <a-tabs :animated="false" defaultActiveKey="1" @change="changeTab">
        <a-tab-pane key="1">
          <span slot="tab">校区仪表盘</span>
          <xqypb />
        </a-tab-pane>
        <a-tab-pane key="2">
          <span slot="tab">课程签约统计</span>
          <kcsjtj />
        </a-tab-pane>
        <a-tab-pane key="3">
          <span slot="tab">开班统计</span>
          <kbtj />
        </a-tab-pane>
        <a-tab-pane key="4">
          <span slot="tab">请假数据统计</span>
          <qjsjtj />
        </a-tab-pane>
        <a-tab-pane key="5">
          <span slot="tab">老师数据统计</span>
          <lssjtj />
        </a-tab-pane>
        <a-tab-pane key="6">
          <span slot="tab">教室数据统计</span>
          <jssjtj />
        </a-tab-pane>

        <a-tab-pane key="7">
          <span slot="tab">课消数据统计</span>
          <kxsjtj />
        </a-tab-pane>

        <a-tab-pane key="8">
          <span slot="tab">库存统计</span>
          <kctj/>
        </a-tab-pane>
        <a-tab-pane key="9">
          <span slot="tab">学员统计</span>
          <xytj/>
        </a-tab-pane>

        <a-tab-pane key="10">
          <span slot="tab">积分统计</span>
          <jftj/>
        </a-tab-pane>
      </a-tabs>
    </div>
  </div>
</template>

<script>
import xqypb from './xqypb/index'
import kcsjtj from './kcsjtj/index'
import kbtj from './kbtj/index'
import qjsjtj from './qjsjtj/index'
import lssjtj from './lssjtj/index'
import jssjtj from './jssjtj/index'
import kcsftj from './kcsftj'
import kctj from './kctj'
import xytj from './xytj/index'
import kxsjtj from './kxsjtj/index'
import jftj from './jftj/index'

export default {
  name: 'Analysis',
  components: {
    xqypb,
    kcsjtj,
    kbtj,
    kctj,
    qjsjtj,
    lssjtj,
    jssjtj,
    kcsftj,
    xytj,
    kxsjtj,
    jftj
  },
  data() {
    return {
    }
  },

  created() {
  },

  methods: {
    changeTab() {

    }
  }
}
</script>
