<template>
    <a-card title="">
    <div>
        <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
        <ve-bar 
        :legend="{show:false}" 
        judge-width
        :grid="{right: '5%'}"
        height="750px"
        :toolbox="toolbox"
        :title="{
            text:'课程学员统计',
            textStyle: {
                fontSize: 15,
            },
            subtextStyle: {
                fontSize: 12.5
            }
        }" 
        :data="studentsData" 
        :settings="{label: { show: true, position: 'right'},xAxisType: ['KMB', 'KMB']}" :extend="{
    xAxis:{
            splitLine: {
            lineStyle: {
                type: 'dotted'
            }
            }
        },
    yAxis:{
        inverse: true,  
        splitLine:{show: false},
        axisLabel: {
            color: '#333',
            rich: {
                b: {
                    color: '#f4bc2f',
                    align: 'center',
                }
            },
            formatter: function(params,index) {
                return [
                        `${params} {b| ${index + 1}}`
                    ].join('\n')
            }
        }
    }, series:{type:'bar',barMaxWidth:10,margin:80,itemStyle:{
        normal:{
            color: function(params) {
                return {
                    type: 'linear',
                    colorStops: [{
                        offset: 0,
                        color: '#effffb'
                        
                    }, {
                        offset: 1,                    
                        color: '#23d9ae'
                    }],
                }
        },barBorderRadius:[0,30,30,0]}}},dataZoom:[{type:'slider'}]}"  ></ve-bar>
        <LEmpty v-if="first>0 && studentsData.rows.length == 0"/>
    </div>
</a-card>
</template>

<script>
    export default {
        name:"classes-students",
        data () {
            this.toolbox = {
                feature: {
                magicType: {},
                saveAsImage: {pixelRatio:2}
                }
            }
            return{
                first:0
            }
        },
        props:['studentsData' , 'loading'],
        watch: {
            loading(newValue, oldValue) {
                this.first++;
            }
        },
    }
</script>

<style lang="scss" scoped>

</style>