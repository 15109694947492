<template>
  <div class="zcxy">
    <div class="clearfix table-tools">
      <div class="buttons">
      </div>
      <div class="search">
        <a-form layout='inline'>
          <a-form-item>
            <a-radio-group :value="paramsData.filter_type" @change="handleButChange">
              <a-radio-button value="day">
                日
              </a-radio-button>
              <a-radio-button value="month">
                月
              </a-radio-button>
              <a-radio-button value="year">
                年
              </a-radio-button>
            </a-radio-group>
          </a-form-item>
          <a-form-item>
            <a-tooltip placement="topLeft" >
              <template slot="title">
                <span>时间筛选</span>
              </template>
              <a-range-picker
                :ranges="rangesData"
                :value='rangesValue'
                :disabled-date="disabledDate" :allowClear="false"
                :default-value="[moment(paramsData.start_date,'YYYY-MM-DD'),moment(paramsData.end_date,'YYYY-MM-DD')]"  style="width: 240px"
                @change="(date, dateString)=>onChange(date, dateString,1)" />
            </a-tooltip>
          </a-form-item>
          <a-form-item>
            <a-tooltip placement="topLeft" >
              <template slot="title">
                <span>上课校区</span>
              </template>
              <a-select allowClear showSearch placeholder="请选择校区" mode="multiple" :maxTagCount="1" :maxTagTextLength='5' :filterOption="filterOption" @change="handleChange" style="width: 220px;">
                <a-select-option v-for="(d, index) of studios" :key="index" :value="d['studio_id']">{{ d['filter_name'] }}</a-select-option>
              </a-select>
            </a-tooltip>
          </a-form-item>
          <a-form-item>
            <a-button @click="toScreen" type="primary">筛选</a-button>
          </a-form-item>
        </a-form>
      </div>
    </div>
    <div class="dashboard bk-group" style="padding:0 20px 0 20px">
      <statistics :count='counts'/>
      <a-row class="mb16" :gutter="16">
          <a-col :lg="24" :xl="12">
            <veRinngL :loading='loading' :item ='ringDataL' />
          </a-col>
          <a-col :lg="24" :xl="12">
            <veRinngR :loading='loading'  :item ='ringDataR' />
          </a-col>
          <a-col :lg="24" :xl="12">
            <veBarL :loading='loading' :item ='ringDataL' :start_date='paramsData.start_date' :end_date='paramsData.end_date' />
          </a-col>
          <a-col :lg="24" :xl="12">
            <veBarR :loading='loading'  :item ='ringDataR' :start_date='paramsData.start_date' :end_date='paramsData.end_date' />
          </a-col>
          <a-col :lg="24" :xl="12">
            <sale :loading='loadingS'  :item ='saleData' :start_date='paramsData.start_date' :end_date='paramsData.end_date' />
          </a-col>
          <a-col :lg="24" :xl="12">
            <contractStatis :loading='loadingC'  :item ='contractStatisData' :start_date='paramsData.start_date' :end_date='paramsData.end_date' />
          </a-col>
          <a-col :lg="24" :xl="12">
            <refundStatis :loading='loadingRS'  :item ='refundStatisData' :start_date='paramsData.start_date' :end_date='paramsData.end_date' />
          </a-col>
          <a-col :lg="24" :xl="12">
            <refundContractStatis :loading='loadingRC'  :item ='refundContractStatisData' :start_date='paramsData.start_date' :end_date='paramsData.end_date' />
          </a-col>
      </a-row>
      
    </div>
    
  </div>
</template>

<script>
import ranges from "@/common/mixins/ranges";
import moment from 'moment'
import veRinngL from './veRingL'
import veRinngR from './veRinngR'
import veBarL from './veBarL'
import veBarR from './veBarR'
import sale from './sale'
import contractStatis from './contractStatis'
import refundStatis from './refundStatis'
import refundContractStatis from './refundContractStatis'
import statistics from '../xqypb/statistics'
export default {
  name: 'kcsjtj',

  data() {
    return {
      loading:false,
      loadingS:false,
      loadingC:false,
      loadingRS:false,
      loadingRC:false,
      ringDataL:[],
      ringDataR:[],
      saleData:[],
      counts:[],
      contractStatisData:[],
      refundStatisData:[],
      refundContractStatisData:[],
      studios:[],
      paramsData:{
        start_date:'',
        end_date:'',
        studio_id:'',
        filter_type:'day'
      },
    }
  },
  mixins: [ranges],
  components:{
    veRinngL,
    veRinngR,
    veBarL,
    veBarR,
    sale,
    statistics,
    contractStatis,
    refundStatis,
    refundContractStatis
  },
  created() {
    this.paramsData.start_date = moment().subtract('days',30).format('YYYY-MM-DD')
    this.paramsData.end_date = moment().subtract('days',0).format('YYYY-MM-DD')
    this.rangesValue = [moment(this.paramsData.start_date,'YYYY-MM-DD'),moment(this.paramsData.end_date,'YYYY-MM-DD')]
    this.getList();
    this.getStudio();
  },

  methods: {
    moment,
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    getList(obj){
      this.getCount(obj)
      this.getRing(obj)
      this.getSale(obj)
      this.getContractStatis(obj)
      this.getRefundStatis(obj)
      this.getRefundContractStatis(obj)
      
    },
    async getRing(obj){
      this.loading = true
      let res = await this.$store.dispatch('analysisSummateAction', obj)
      this.ringDataL = res.data[0]
      this.ringDataR = res.data[1]
      this.loading = false
    },
    async getSale(obj){
      this.loadingS = true
      let resS = await this.$store.dispatch('analysisSaleStatisAction', obj)
      this.saleData = resS.data
      this.loadingS = false
    },
    async getContractStatis(obj){
      this.loadingC = true
      let resC = await this.$store.dispatch('analysisContractStatisAction', obj)
      this.contractStatisData = resC.data
      this.loadingC = false
    },
    async getRefundStatis(obj){
      this.loadingRS = true
      let resRS = await this.$store.dispatch('analysisRefundStatisAction', obj)
      this.refundStatisData = resRS.data
      this.loadingRS = false
    },
    async getRefundContractStatis(obj){
      this.loadingRC = true
      let resRC = await this.$store.dispatch('analysisRefundContractStatisAction', obj)
      this.refundContractStatisData = resRC.data
      this.loadingRC = false
    },
    async getCount(obj){
      let res = await this.$store.dispatch('analysisCourseCountAction', obj)
      this.counts = res.data
    },
    async getStudio() {
      let res = await this.$store.dispatch('searchBelongStudioAction', {})
      this.studios = res.data
    },
    handleChange(value){
      this.paramsData.studio_id = value;
    },
    handleButChange(e){
      this.paramsData.filter_type = e.target.value
      this.toScreen();
    },
    toScreen(){
      let obj = {
        search:this.paramsData
      }
      this.getList(obj);
    }
  }
}
</script>
